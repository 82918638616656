import React from 'react';
import { Helmet } from 'react-helmet';
import Box from 'components/box';
import Layout from 'components/layout';

const NotFound = () => (
  <Layout>
    <Helmet>
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
    <Box>Not found.</Box>
  </Layout>
);

export default NotFound;
